import React, { useCallback, useRef } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import TextButton from '@internal/ui/legacy/components/TextButton';
import Logo from '@internal/ui/svg/logo-white.svg';
import FadeOverlayStyles from '../../styles/common/fadeOverlay';
import {
    MenuButtonStyled,
    RedTransparentButtonStyled,
    RedTransparentButtonStyles,
} from '../../styles/common/marketing';
import MenuDots from '../../public/img/menu-dots.svg';
import HomeIcon from '../../public/img/home.svg';
import CloseIcon from '@internal/ui/svg/close.svg';
import Link from 'next/link';
import useDetectClickOutside from '@internal/ui/legacy/hooks/useDetectClickOutside';

export enum MarketingNavOptions {
    Join = 'Join',
    Signup = 'Signup',
    Login = 'Login',
    Features = 'Features',
    Careers = 'Careers',
    About = 'About',
}

interface MarketingNav {
    className?: string;
    menu?: boolean;
    open: boolean;
    mobile: boolean;
    loggedIn: boolean;
    initialized: boolean;
    onClickOption?: (option: MarketingNavOptions) => void;
    onClickMenu?: () => void;
    onClose: () => void;
}

const MarketingNav: React.FunctionComponent<MarketingNav> = (props) => {
    const {
        className,
        menu = false,
        open,
        mobile,
        loggedIn,
        initialized,
        onClickMenu,
        onClose,
        onClickOption,
    } = props;

    const navRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<HTMLDivElement>(null);

    useDetectClickOutside({
        enabled: open && menu,
        elements: [navRef.current, menuRef.current],
        mobile,
        escapeKey: true,
        onClickOutside: useCallback(() => {
            onClose();
        }, []),
    });

    return (
        <>
            <MarketingNavStyled ref={navRef} className={className}>
                <Link href="/">
                    <LogoContainerStyled href="/" title="Playback">
                        <Logo />
                    </LogoContainerStyled>
                </Link>
                {initialized && (
                    <>
                        {!loggedIn && !mobile && !open && (
                            <>
                                <SignupButtonStyled
                                    onClick={() => {
                                        onClickOption(MarketingNavOptions.Signup);
                                    }}
                                >
                                    Sign up
                                </SignupButtonStyled>
                                <LoginButtonStyled
                                    onClick={() => {
                                        onClickOption(MarketingNavOptions.Login);
                                    }}
                                >
                                    Log in
                                </LoginButtonStyled>
                            </>
                        )}
                        {(menu || open) && (
                            <FixedMenuButtonStyled
                                onClick={() => {
                                    if (open) {
                                        onClose();
                                    } else {
                                        onClickMenu();
                                    }
                                }}
                            >
                                {open ? <CloseIcon /> : <MenuDots />}
                            </FixedMenuButtonStyled>
                        )}
                        {loggedIn && !menu && !open && (
                            <>
                                <Link href="/home">
                                    <HomeButtonDesktopStyled href="/home" title="Playback Home">
                                        My rooms
                                    </HomeButtonDesktopStyled>
                                </Link>
                                <Link href="/home">
                                    <HomeButtonMobileStyled href="/home" title="Playback Home">
                                        <HomeIcon />
                                    </HomeButtonMobileStyled>
                                </Link>
                            </>
                        )}
                    </>
                )}
            </MarketingNavStyled>
            {initialized && menu && (
                <MenuStyled ref={menuRef} visible={open}>
                    <Menu_ContentsStyled>
                        <Menu_ItemStyled>
                            <button
                                onClick={() => {
                                    onClickOption(MarketingNavOptions.Join);
                                }}
                            >
                                Apply
                            </button>
                        </Menu_ItemStyled>
                        <Menu_ItemStyled>
                            <button
                                onClick={() => {
                                    onClickOption(MarketingNavOptions.Signup);
                                }}
                            >
                                Sign up
                            </button>
                        </Menu_ItemStyled>
                        <Menu_ItemStyled>
                            <button
                                onClick={() => {
                                    onClickOption(MarketingNavOptions.Login);
                                }}
                            >
                                Log in
                            </button>
                        </Menu_ItemStyled>
                    </Menu_ContentsStyled>
                </MenuStyled>
            )}
        </>
    );
};

const HomeButtonDesktopStyled = styled.a`
    ${RedTransparentButtonStyles}
`;

const HomeButtonMobileStyled = styled(HomeButtonDesktopStyled)`
    padding: 0;
    width: ${rem(40)};

    svg {
        height: ${rem(20)};
        width: ${rem(20)};
    }
`;

const FixedMenuButtonStyled = styled(MenuButtonStyled)`
    position: fixed;
    top: ${rem(16)};
    right: ${rem(16)};
    z-index: 2;

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        top: ${rem(32)};
        right: ${rem(32)};
    }
`;

const Menu_ItemStyled = styled.li`
    > * {
        ${({ theme }) => theme.typography.FontMedium}
        font-size: ${rem(54)};
        color: ${({ theme }) => theme.palette.White};
        line-height: 130%;
        letter-spacing: -2.5%;

        @media (hover: hover) {
            &:hover {
                text-decoration: underline;
                text-underline-offset: ${rem(6)};
                text-decoration-thickness: ${rem(4)};
            }
        }

        @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
            font-size: ${rem(72)};

            &:hover {
                text-decoration: underline;
                text-underline-offset: ${rem(6)};
                text-decoration-thickness: ${rem(6)};
            }
        }
    }

    & + & {
        margin-top: ${rem(8)};
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        & + & {
            margin-top: ${rem(4)};
        }
    }
`;

const Menu_ContentsStyled = styled.ul`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const MenuStyled = styled.div<{ visible: boolean }>`
    background-color: ${({ theme }) => theme.palette.WineRed};
    ${FadeOverlayStyles}
    z-index: 9;
`;

const SignupButtonStyled = styled(RedTransparentButtonStyled)`
    margin-right: ${rem(12)};
`;

const LoginButtonStyled = styled(TextButton)`
    display: none;
    color: ${({ theme }) => theme.palette.Red};
    font-size: ${rem(16)};

    @media (hover: hover) {
        &:hover {
            color: ${({ theme }) => theme.palette.Red};
        }
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        display: inline-flex;
    }
`;

const LogoContainerStyled = styled.a`
    position: absolute;
    left: 50%;
    top: ${rem(20)};
    transform: translateX(-50%);
    display: flex;

    svg {
        height: ${rem(30)};
        width: ${rem(157)};
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        top: ${rem(36)};

        svg {
            height: ${rem(36)};
            width: ${rem(192)};
        }
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Large)}) {
        top: ${rem(44)};
    }
`;

const MarketingNavStyled = styled.header`
    height: ${rem(40)};
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: ${rem(16)};
    position: relative;
    z-index: 10;

    ${HomeButtonDesktopStyled} {
        display: none;
    }

    ${HomeButtonMobileStyled} {
        display: flex;
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Small)}) {
        padding: ${rem(32)};

        ${HomeButtonDesktopStyled} {
            display: flex;
        }

        ${HomeButtonMobileStyled} {
            display: none;
        }
    }

    @media screen and (min-width: ${({ theme }) => rem(theme.breakpoints.Large)}) {
        height: ${rem(54)};
    }
`;

export default MarketingNav;
